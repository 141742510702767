/* Import this to enable Futura Fonts in any child components */
import { Global } from '@mantine/core';
import light from '../fonts/Font_Futura_PT/FuturaPTLight.otf';
import medium from '../fonts/Font_Futura_PT/FuturaPTBook.otf';

export function CustomFonts() {
  return (
    <Global
      styles={[
        {
          '@font-face': {
            fontFamily: 'FuturaLight',
            src: `url('${medium}') format("opentype")`,
            fontWeight: 700,
            fontStyle: 'normal',
          },
        }
      ]}
    />
  );
}