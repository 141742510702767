/* This file implements all required API calls and preprocessing of API data*/
import axios from "axios";

// Generic function to retrieve data from an endpoint in the API
export const getApiData = async (path, defaultVal) => {
  try {
    let response = await axios.get(process.env.REACT_APP_PLUTUS_API + path, {
      headers: {
        Authorization: "Token " + process.env.REACT_APP_POOL_PLUTUS_AUTH,
      },
    });
    if (response && response.data) {
      return response.data;
    } else {
      return defaultVal;
    }
  } catch (e) {
    console.log(e);
    return defaultVal;
  }
};
