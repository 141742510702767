/* Displays the logo and title */
import { Group, Text, Image, Title } from "@mantine/core";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../hooks/WindowSize";
import "./header.css";

export default function HeaderContent() {
  const { height, width } = useWindowDimensions();
  const routeChange = (uri) => {
    window.open(uri, "_blank", "noreferrer");
  };
  let isBigScreen = true;
  if (width < 1500) {
    isBigScreen = false;
  }

  return (
    <Group position="apart" spacing={24} grow sx={{ height: "100%" }}>
      <Group spacing={24} sx={{ paddingLeft: "24px", flexWrap: "nowrap" }}>
        <Image
          radius="md"
          src="/apple-touch-icon.png"
          alt="LOGO"
          width={43}
          height={43}
          fit="contain"
          onClick={() => routeChange(process.env.REACT_APP_POOL_HOMEPAGE_URL)}
          sx={{ cursor: "pointer" }}
        />
        <Link to={"/"} className="headerLink">
          <Text size={18} fw={300} sx={{ letterSpacing: "1px" }}>
            MINING
          </Text>
        </Link>
        {/* <Link to={"/stake"} className="headerLink">
          <Text size={18} fw={300} sx={{ letterSpacing: "1px" }}>
            STAKING
          </Text>
        </Link> */}
        {/* <Text
          className="headerLink"
          size={18}
          fw={300}
          sx={{ letterSpacing: "1px", cursor: "pointer" }}
          onClick={() =>
            routeChange("https://dash.video-miner.xyz/grafana/dashboards")
          }
        >
          METRICS
        </Text> */}
        {/* <Link to={"/broadcast"} className="headerLink">
          <Text size={18} fw={300} sx={{ letterSpacing: "1px" }}>
            BROADCAST
          </Text>
        </Link> */}
      </Group>
      {isBigScreen ? (
        <Group position="center" spacing={24}>
          <Title order={3} sx={{ letterSpacing: "1px" }}>
            VIDEO MINER
          </Title>
        </Group>
      ) : null}
      {isBigScreen ? <Group position="center" spacing={24}></Group> : null}
    </Group>
  );
}
