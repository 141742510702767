/* Routes to the appropriate page and makes API data available to them */
import { Routes, Route } from "react-router-dom";
import WithPlutus from "../hooks/WithPlutus";
import DebugPage from "./DebugPage";

export default function LoadingScreen() {
  const {
    poolInfo,
    transcoderInfo,
  } = WithPlutus();

  return (
    <Routes>
      <Route
        path="*"
        element={
          <DebugPage
            poolInfo={poolInfo}
            transcoderInfo={transcoderInfo}
          />
        }
      />
    </Routes>
  );
}
