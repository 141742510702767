/* Provide access to API data */
import { useEffect, useState } from "react";
import { getApiData } from "../util/Plutus";
import web3 from "web3";
const refreshInterval = 10000; // auto refresh, in milliseconds

const WithPlutus = () => {
  const [poolInfo, setPools] = useState([]);
  const [transcoderInfo, setTranscoders] = useState([]);

  // Retrieves all pool instances from the poolOrchestrators endpoint
  // This endpoint contains info on each Orchestrator instance mapped to a poolConfig
  const refreshPools = async () => {
    const rawData = await getApiData("/poolOrchestrators/", []);
    const newPoolInfo = [];

    // Iterate over Orchestrator instances
    for (const orchInstance of rawData) {
      // Update global pool info
      let exists = false;
      // Build instance object
      const instanceObj = {
        uuid: orchInstance.uuid,
        name: orchInstance.name,
        serviceUri: orchInstance.serviceUri,
        region: orchInstance.region,
        online: orchInstance.online,
        capacity: orchInstance.capacity,
        load: orchInstance.load,
        latitude: orchInstance.latitude,
        longitude: orchInstance.longitude,
        connections: [],
      };

      for (const pool of newPoolInfo) {
        if (pool.id == orchInstance.poolConfig.id) {
          exists = true;
        } else {
          continue;
        }
        pool.totalCapacity += orchInstance.capacity;
        pool.totalLoad += orchInstance.load;
        pool.instances.push(instanceObj);
        // Omitting: apiHostname
        // Omitting: testBroadcasterRTMP
        // Omitting: testBroadcasterCLI
        // Omitting: lastSourceRecordTimestamp
        // Omitting: lastSourceLogTimestamp
        // Omitting: whitelisted
      }
      // Or init new global pool info
      if (!exists) {
        newPoolInfo.push({
          id: orchInstance.poolConfig.id,
          poolName: orchInstance.poolConfig.poolName,
          poolAddr: orchInstance.poolConfig.poolAddr,
          poolCommission: orchInstance.poolConfig.poolCommission,
          vmpCommission: orchInstance.poolConfig.vmpCommission,
          payoutThreshold: orchInstance.poolConfig.payoutThreshold,
          totalRedeemed: orchInstance.poolConfig.totalRedeemed,
          totalPaid: orchInstance.poolConfig.totalPaid,
          payoutSurplus: orchInstance.poolConfig.payoutSurplus,
          payoutDeficit: orchInstance.poolConfig.payoutDeficit,
          instances: [instanceObj],
          totalCapacity: orchInstance.capacity,
          totalLoad: orchInstance.load,
        });
        // Omitting: id
        // Omitting: maxGasPrice
        // Omitting: gasLimit
        // Omitting: rpcAddr
        // Omitting: lastPayoutBlock
      }
    }

    return newPoolInfo;
  };

  // Retrieves all transcoder instances from the transcoderInstances endpoint
  // This endpoint contains info on each Transcoder instance mapped to a global transcoder object
  const refreshTranscoders = async () => {
    const rawData = await getApiData("/transcoderInstances/", []);
    const newTranscoderInfo = [];

    // Iterate over Transcoder instances
    for (const transcoderInstance of rawData) {
      // Update global transcoder info
      let exists = false;
      for (const transcoder of newTranscoderInfo) {
        if (transcoder.ethAddr == transcoderInstance.transcoder.ethAddr) {
          exists = true;
        } else {
          continue;
        }
        transcoder.instances.push({
          uuid: transcoderInstance.uuid,
          name: transcoderInstance.name,
          load: transcoderInstance.load,
          totalCapacity: transcoderInstance.totalCapacity,
          remainingCapacity: transcoderInstance.remainingCapacity,
          online: transcoderInstance.online,
          latitude: transcoderInstance.latitude,
          longitude: transcoderInstance.longitude,
          connections: [],
        });
        // Omitting: whitelisted
        // Omitting: ipAddr
      }
      // Or init new global transcoder info
      if (!exists) {
        newTranscoderInfo.push({
          ethAddr: transcoderInstance.transcoder.ethAddr,
          nickname: transcoderInstance.transcoder.nickname,
          vested: transcoderInstance.transcoder.vested,
          payout: transcoderInstance.transcoder.payout,
          totalEarned: transcoderInstance.transcoder.totalEarned,
          instances: [],
          balances: [],
          payments: [],
        });
        // Omitting: checksum
        // Omitting: whitelist
      }
    }

    return newTranscoderInfo;
  };

  // Retrieves all transcoder connections, from a transcoder instance to a orchestrator instance
  // This endpoint contains info on each Transcoder instance mapped to a global transcoder object
  const refreshConnections = async () => {
    const rawData = await getApiData("/transcoderConnections/", []);
    const newConnectionInfo = [];

    // Iterate over Orchestrator instances
    for (const connection of rawData) {
      newConnectionInfo.push({
        load: connection.load,
        connectedSince: connection.timeConnectionEstablished,
        transcoderUUID: connection.transcoderInstance.uuid,
        transcoderETH: connection.transcoderInstance.transcoder.ethAddr,
        transcoderNick: connection.transcoderInstance.transcoder.nickname,
        orchestratorUUID: connection.poolOrchestrator.uuid,
        orchestratorNick: connection.poolOrchestrator.name,
      });
      // Omitting: id
      // Omitting: lastUpdated
      // Omitting: any nested transcoderInstance data - already contained in transcoderInfo
      // Omitting: any nested poolOrchestrator data - already contained in poolInfo
    }

    return newConnectionInfo;
  };

  // Retrieves all transcoder balances for each sub-pool
  const refreshBalances = async () => {
    const rawData = await getApiData("/transcoderPoolBalances/", []);
    const newBalanceInfo = [];

    for (const connection of rawData) {
      newBalanceInfo.push({
        totalEarned: connection.totalEarned,
        pixels: connection.pixels,
        millisecond: connection.millisecond,
        segmentValue: connection.segmentValue,
        vested: connection.vested,
        totalPaid: connection.totalPaid,
        transcoderEthAddr: connection.transcoder.ethAddr,
        poolID: connection.poolConfig.id,
      });
      // Omitting: any nested transcoder data
      // Omitting: any nested poolConfig data
    }

    return newBalanceInfo;
  };

  // Updates payment state
  const refreshPayments = async () => {
    const paymentData = await getApiData("/payments/", []);
    return paymentData;
  };

  // Calls all refresh functions
  const refreshAllZeData = async () => {
    const poolPromise = refreshPools();
    const paymentPromise = refreshPayments();
    const transcoderPromise = refreshTranscoders();
    const connectionPromise = refreshConnections();
    const balancePromise = refreshBalances();
    await Promise.all([
      poolPromise,
      paymentPromise,
      transcoderPromise,
      connectionPromise,
      balancePromise,
    ]).then((values) => {
      const newPoolInfo = values[0];
      const newPaymentInfo = values[1];
      const newTranscoders = values[2];
      const newConnections = values[3];
      const newBalances = values[4];
      // Merge connection info to orch and transcoder instances
      for (const connection of newConnections){
        for (const transcoder of newTranscoders){
          for (const instance of transcoder.instances){
            if (instance.uuid == connection.transcoderUUID){
              instance.connections.push(connection);
            }
          }
        }
        for (const orchestrator of newPoolInfo){
          for (const instance of orchestrator.instances){
            if (instance.uuid == connection.orchestratorUUID){
              instance.connections.push(connection);
            }
          }
        }
      }
      // Merge balance info to transcoder instances
      for (const balance of newBalances){
        for (const transcoder of newTranscoders){
          if (transcoder.ethAddr == balance.transcoderEthAddr){
            transcoder.balances.push(balance);
          }
        }
      }
      // Merge payment info to transcoder instances
      for (const payment of newPaymentInfo){
        for (const transcoder of newTranscoders){
          if (transcoder.ethAddr == payment.recipient){
            transcoder.payments.push(payment);
          }
        }
      }
      setPools(newPoolInfo);
      setTranscoders(newTranscoders);
    });
  };

  // Set the refresh interval for the refresh function
  useEffect(() => {
    if (refreshInterval) {
      const interval = setInterval(refreshAllZeData, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);

  // Immediately refresh all data on first render
  useEffect(() => {
    refreshAllZeData();
  }, []);

  return {
    poolInfo,
    transcoderInfo,
  };
};

export default WithPlutus;
