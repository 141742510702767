/* 
Renders unformatted, processed Plutus API data
*/
import { Stack, Text } from "@mantine/core";
import ViewSwitcher from "./ViewSwitcher";

/*

  Pool Orchestrators

*/

const OrchestratorInstance = ({ orchInfo }) => {
  return (
    <Stack spacing={"0px"} sx={{ marginLeft: "0px", marginRight: "0px" }}>
      <Text fz="lg">Pool Instance {orchInfo.name}</Text>
      <Stack spacing={"0px"} sx={{ marginLeft: "14px", marginRight: "14px" }}>
        <Text>uuid: {orchInfo.uuid}</Text>
        <Text>name: {orchInfo.name}</Text>
        <Text>serviceUri: {orchInfo.serviceUri}</Text>
        <Text>region: {orchInfo.region}</Text>
        <Text>online: {orchInfo.online ? "ONLINE" : "OFFLINE"}</Text>
        <Text>capacity: {orchInfo.capacity}</Text>
        <Text>load: {orchInfo.load}</Text>
        <Text>latitude: {orchInfo.latitude}</Text>
        <Text>longitude: {orchInfo.longitude}</Text>
        <Stack
          spacing={"14px"}
          sx={{ marginLeft: "14px", marginRight: "14px" }}
        >
          <Text fz="lg">Connections</Text>
          {orchInfo.connections.map(function (connection, idx) {
            return (
              <Stack
                spacing={"0px"}
                sx={{ marginLeft: "14px", marginRight: "14px" }}
              >
                <Text>load: {connection.load}</Text>
                <Text>connectedSince: {connection.connectedSince}</Text>
                <Text>transcoderUUID: {connection.transcoderUUID}</Text>
                <Text>transcoderETH: {connection.transcoderETH}</Text>
                <Text>transcoderNick: {connection.transcoderNick}</Text>
                <Text>orchestratorUUID: {connection.orchestratorUUID}</Text>
                <Text>orchestratorNick: {connection.orchestratorNick}</Text>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
      <hr />
    </Stack>
  );
};

const OrchestratorInfo = ({ poolInfo }) => {
  return (
    <Stack spacing={"0px"} sx={{ marginLeft: "0px", marginRight: "0px" }}>
      <Text fz="lg">Pool {poolInfo.poolName}</Text>
      <Stack spacing={"0px"} sx={{ marginLeft: "14px", marginRight: "14px" }}>
        <Text>id: {poolInfo.id}</Text>
        <Text>poolName: {poolInfo.poolName}</Text>
        <Text>poolAddr: {poolInfo.poolAddr}</Text>
        <Text>poolCommission: {poolInfo.poolCommission}</Text>
        <Text>vmpCommission: {poolInfo.vmpCommission}</Text>
        <Text>payoutThreshold: {poolInfo.payoutThreshold}</Text>
        <Text>totalRedeemed: {poolInfo.totalRedeemed}</Text>
        <Text>totalPaid: {poolInfo.totalPaid}</Text>
        <Text>payoutSurplus: {poolInfo.payoutSurplus}</Text>
        <Text>payoutDeficit: {poolInfo.payoutDeficit}</Text>
        <Text>totalCapacity: {poolInfo.totalCapacity}</Text>
        <Text>totalLoad: {poolInfo.totalLoad}</Text>
        <Stack
          spacing={"14px"}
          sx={{ marginLeft: "14px", marginRight: "14px" }}
        >
          {poolInfo.instances.map(function (orchInfo, idx) {
            return (
              <OrchestratorInstance key={orchInfo.uuid} orchInfo={orchInfo} />
            );
          })}
        </Stack>
      </Stack>
      <hr style={{ width: "100%" }} />
    </Stack>
  );
};

const OrchestratorSummary = ({ orchestratorNodes }) => {
  return (
    <Stack spacing={"0px"} sx={{ marginLeft: "0px", marginRight: "0px" }}>
      <Text fz="lg">Pools</Text>
      <hr style={{ width: "100%" }} />
      {orchestratorNodes.map(function (poolInfo, idx) {
        return <OrchestratorInfo key={poolInfo.poolName} poolInfo={poolInfo} />;
      })}
    </Stack>
  );
};

/*

  Pool Transcoders

*/

const TranscoderInstances = ({ transcoderInfo }) => {
  return (
    <Stack spacing={"0px"} sx={{ marginLeft: "0px", marginRight: "0px" }}>
      <Text fz="lg">Transcoder Instance {transcoderInfo.name}</Text>
      <Stack spacing={"0px"} sx={{ marginLeft: "14px", marginRight: "14px" }}>
        <Text>uuid: {transcoderInfo.uuid}</Text>
        <Text>name: {transcoderInfo.name}</Text>
        <Text>load: {transcoderInfo.load}</Text>
        <Text>totalCapacity: {transcoderInfo.totalCapacity}</Text>
        <Text>remainingCapacity: {transcoderInfo.remainingCapacity}</Text>
        <Text>online: {transcoderInfo.online ? "ONLINE" : "OFFLINE"}</Text>
        <Text>latitude: {transcoderInfo.latitude}</Text>
        <Text>longitude: {transcoderInfo.longitude}</Text>
        <Stack
          spacing={"14px"}
          sx={{ marginLeft: "14px", marginRight: "14px" }}
        >
          <Text fz="lg">Connections</Text>
          {transcoderInfo.connections.map(function (connection, idx) {
            return (
              <Stack
                spacing={"0px"}
                sx={{ marginLeft: "14px", marginRight: "14px" }}
              >
                <Text>load: {connection.load}</Text>
                <Text>connectedSince: {connection.connectedSince}</Text>
                <Text>transcoderUUID: {connection.transcoderUUID}</Text>
                <Text>transcoderETH: {connection.transcoderETH}</Text>
                <Text>transcoderNick: {connection.transcoderNick}</Text>
                <Text>orchestratorUUID: {connection.orchestratorUUID}</Text>
                <Text>orchestratorNick: {connection.orchestratorNick}</Text>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
      <hr />
    </Stack>
  );
};

const TranscoderInfo = ({ transcoderInfo }) => {
  return (
    <Stack spacing={"0px"} sx={{ marginLeft: "0px", marginRight: "0px" }}>
      <Text fz="lg">Transcoder {transcoderInfo.nickname}</Text>
      <Stack spacing={"0px"} sx={{ marginLeft: "14px", marginRight: "14px" }}>
        <Text>ethAddr: {transcoderInfo.ethAddr}</Text>
        <Text>nickname: {transcoderInfo.nickname}</Text>
        <Text>vested: {transcoderInfo.vested}</Text>
        <Text>payout: {transcoderInfo.payout}</Text>
        <Text>totalEarned: {transcoderInfo.totalEarned}</Text>
        <Stack
          spacing={"14px"}
          sx={{ marginLeft: "14px", marginRight: "14px" }}
        >
          {transcoderInfo.instances.map(function (instance, idx) {
            return (
              <TranscoderInstances
                key={instance.uuid}
                transcoderInfo={instance}
              />
            );
          })}
        </Stack>
        <Stack
          spacing={"14px"}
          sx={{ marginLeft: "14px", marginRight: "14px" }}
        >
          <Text fz="lg">Balances</Text>
          {transcoderInfo.balances.map(function (balance, idx) {
            return (
              <Stack
                spacing={"0px"}
                sx={{ marginLeft: "14px", marginRight: "14px" }}
              >
                <Text>totalEarned: {balance.totalEarned}</Text>
                <Text>pixels: {balance.pixels}</Text>
                <Text>millisecond: {balance.millisecond}</Text>
                <Text>segmentValue: {balance.segmentValue}</Text>
                <Text>vested: {balance.vested}</Text>
                <Text>totalPaid: {balance.totalPaid}</Text>
                <Text>transcoderEthAddr: {balance.transcoderEthAddr}</Text>
                <Text>poolID: {balance.poolID}</Text>
              </Stack>
            );
          })}
        </Stack>
        <Stack
          spacing={"14px"}
          sx={{ marginLeft: "14px", marginRight: "14px" }}
        >
          <Text fz="lg">Payments</Text>
          {transcoderInfo.payments.map(function (payment, idx) {
            return (
              <Stack
                spacing={"0px"}
                sx={{ marginLeft: "14px", marginRight: "14px" }}
              >
                <Text>txHash: {payment.txHash}</Text>
                <Text>recipient: {payment.recipient}</Text>
                <Text>sender: {payment.sender}</Text>
                <Text>amount: {payment.amount}</Text>
                <Text>gas: {payment.gas}</Text>
                <Text>gasPrice: {payment.gasPrice}</Text>
                <Text>timestamp: {payment.timestamp}</Text>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
      <hr style={{ width: "100%" }} />
    </Stack>
  );
};

const TranscoderSummary = ({ transcoderInfo }) => {
  // console.log(transcoderInfo);
  return (
    <Stack spacing={"14px"} sx={{ marginLeft: "0px", marginRight: "0px" }}>
      <Text fz="lg">Transcoders</Text>
      <hr style={{ width: "100%" }} />
      {transcoderInfo.map(function (transcoder, idx) {
        return (
          <TranscoderInfo
            key={transcoder.ethAddr}
            transcoderInfo={transcoder}
          />
        );
      })}
    </Stack>
  );
};

// Page Render

export default function DebugPage({ poolInfo, transcoderInfo }) {
  return (
    <Stack spacing={"14px"} sx={{ marginLeft: "14px", marginRight: "14px" }}>
      <ViewSwitcher
        components={[
          <OrchestratorSummary orchestratorNodes={poolInfo || []} />,
          <TranscoderSummary transcoderInfo={transcoderInfo || []} />,
        ]}
      />
    </Stack>
  );
}
