/* Wraps an shell with a sidebar and header around the child component */
import { AppShell, Header } from "@mantine/core";
import HeaderContent from "./components/Header/Header";

export default function Shell(props: any) {
  return (
    <AppShell
      padding={28}
      navbar={undefined}
      header={
        <Header height={80} p="xs" sx={{ backgroundColor: "#222629" }}>
          <HeaderContent />
        </Header>
      }
      sx={{ overflowX: "hidden" }}
      styles={(theme) => ({
        main: {
          background: theme.fn.radialGradient("#36393f", "#222629"),
          paddingLeft: 0,
          paddingRight: 0
        },
      })}
    >
      {props.children}
    </AppShell>
  );
}
