/* 
Compacts children into a carousel on smaller screens
*/
import { Group, Box, Flex } from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import useWindowDimensions from "../hooks/WindowSize";

export default function ViewSwitcher(props) {
  const { height, width } = useWindowDimensions();
  if (width > 1200) {
    return (
      <Group
        position="apart"
        spacing="28px"
        grow
        sx={{ display: "flex", alignItems: "stretch" }}
      >
        {props.components.map(function (thisChild, idx) {
          return (
            <Box
              key={"view-" + idx}
              sx={{
                backgroundColor: "#222629",
                borderRadius: "7px",
                padding: "28px",
                flex: 1,
              }}
            >
              {thisChild}
            </Box>
          );
        })}
      </Group>
    );
  } else {
    return (
      <div style={{ display: "flex" }}>
        <Carousel
          slideGap={28}
          controlsOffset="xs"
          loop
          draggable={true}
          withControls={false}
          withIndicators
          styles={{
            indicator: {
              width: 12,
              height: 4,
              transition: "width 250ms ease",
              "&[data-active]": {
                width: 40,
              },
            },
            indicators: {
              bottom: "-14px",
            },
          }}
          sx={{ flex: 1, width: "100%" }}
        >
          {props.components.map(function (thisChild, idx) {
            return (
              <Carousel.Slide
                key={"view-" + idx}
                sx={{ display: "flex", width: "100%" }}
              >
                <Flex
                  mih={50}
                  gap="xl"
                  direction="column"
                  wrap="nowrap"
                  sx={{
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#222629",
                      borderRadius: "7px",
                      padding: "28px",
                      maxHeight: "calc(100vh - 200px)",
                      overflowY: "auto",
                      overflowX: "hidden"
                    }}
                  >
                    {thisChild}
                  </Box>
                </Flex>
              </Carousel.Slide>
            );
          })}
        </Carousel>
      </div>
    );
  }
}
