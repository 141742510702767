/* 
Renders the App in index.html in the element with id `root`
Wraps the UI framework and header around loading element
*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import LoadingScreen from "./pages/LoadingScreen";
import Shell from "./Shell";
import { CustomFonts } from "./fonts/WithFonts";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <CustomFonts />
      <MantineProvider
        withCSSVariables
        withNormalizeCSS
        withGlobalStyles
        theme={{
          colors: {
            livepeerGreen: [
              "#F4FAEB",
              "#DFF0C6",
              "#CAE7A2",
              "#B5DE7D",
              "#A1D459",
              "#8CCB34",
              "#70A22A",
              "#547A1F",
              "#385115",
              "#1C290A",
            ],
            livepeerGreenShade: [
              "#F3F8EC",
              "#DDEDCA",
              "#C8E1A8",
              "#B2D585",
              "#9CCA63",
              "#86BE41",
              "#6C9834",
              "#517227",
              "#364C1A",
              "#1B260D",
            ],
          },
          primaryColor: "livepeerGreen",
          primaryShade: { light: 6, dark: 8 },
          colorScheme: "dark",
          fontFamily: "FuturaLight",
        }}
      >
        <Shell>
          <LoadingScreen />
        </Shell>
      </MantineProvider>
    </BrowserRouter>
  </React.StrictMode>
);
